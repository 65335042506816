<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Update Postcode Groups">
          <validation-observer
            ref="updatePostcodeGroupValidator"
          >
            <b-form @submit.prevent="onSubmit">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Postcode Group Name"
                    label-for="postcode"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="group name"
                      rules="required"
                    >
                      <b-form-input
                        id="postcode_group"
                        v-model="form.group_name"
                        name="postcode_group"
                        placeholder="Enter group name"
                      />
                      <span class="text-danger error-msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Select Postcodes"
                    label-for="postcode"
                  >
                    <validation-provider
                      v-slot="{errors}"
                      name="postcodes"
                      :rules="{ required: true }"
                    >
                      <multiselect
                        v-model="selectedPostcodes"
                        :options="postcodes"
                        :multiple="true"
                        label="code"
                        track-by="id"
                        open-direction="bottom"
                        :show-no-results="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :preselect-first="false"
                        :internal-search="true"
                        :close-on-select="true"
                        :allow-empty="false"
                        class="m-0"
                      />
                      <span class="text-danger error-msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Select weekdays">
                    <validation-provider
                      v-slot="{errors}"
                      name="repeat week"
                      :rules="{ required: true }"
                    >
                      <b-form-checkbox-group
                        v-model="selectedWeekdays"
                        :options="weekdays"
                        value-field="value"
                        text-field="text"
                      />
                      <span class="text-danger error-msg mt-1">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>

                  <b-row
                    v-for="(weekday, index) in selectedWeekdays"
                    :key="index"
                  >
                    <b-col cols="12">
                      <p class="mt-2">
                        Select options for <strong>{{ weekday }}</strong>
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="d-flex align-items-center w-100">
                        <b-form-group class="w-100 m-0">
                          <validation-provider
                            v-slot="{errors}"
                            :name="`repeat week for ${weekday}`"
                            :rules="{ required: true }"
                          >
                            <multiselect
                              v-model="selectedRepeat[index]"
                              class="w-100"
                              :options="repeatOptions"
                              :multiple="false"
                              open-direction="bottom"
                              :name="`${weekday}`"
                              :show-no-results="false"
                              :clear-on-select="false"
                              :preserve-search="true"
                              :preselect-first="false"
                              :internal-search="true"
                              :close-on-select="true"
                              :allow-empty="false"
                              placeholder="Select Repeat"
                            />
                            <span class="text-danger error-msg">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100 m-0">
                          <validation-provider
                            v-slot="{errors}"
                            :name="`start date for ${weekday}`"
                            :rules="{ required: true }"
                          >
                            <b-form-datepicker
                              v-model="selectedStartDate[index]"
                              placeholder="Start Date"
                            />
                            <span class="text-danger error-msg">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-end mt-4">
                    <b-button
                      variant="primary"
                      type="submit"
                    >
                      Update
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const postcodeModule = createNamespacedHelpers('postcode')
export default {
  data() {
    return {
      current_group_name: null,
      form: {
        group_name: '',
        postal_code: [],
        weekdays: [
          {
            name: 'Monday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
          {
            name: 'Tuesday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
          {
            name: 'Wednesday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
          {
            name: 'Thursday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
          {
            name: 'Friday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
          {
            name: 'Saturday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
          {
            name: 'Sunday',
            isSelected: false,
            startDate: null,
            repeatWeek: null,
          },
        ],
      },
      postcodes: [],
      selectedPostcodes: [],
      repeatOptions: [
        'Every Week',
        'After Every 1 Week',
        'After Every 2 Week',
        'After Every 3 Week',
        'After Every 4 Week',
      ],
      weekdays: [
        { text: 'Monday', value: 'Monday' },
        { text: 'Tuesday', value: 'Tuesday' },
        { text: 'Wednesday', value: 'Wednesday' },
        { text: 'Thursday', value: 'Thursday' },
        { text: 'Friday', value: 'Friday' },
        { text: 'Saturday', value: 'Saturday' },
        { text: 'Sunday', value: 'Sunday' },
      ],
      selectedWeekdays: [],
      selectedRepeat: [],
      selectedStartDate: [],
    }
  },
  async mounted() {
    this.UPDATE_LOADER(true)
    await this.fetchAllPostcodes()
    this.UPDATE_LOADER(false)
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...postcodeModule.mapActions(['FETCH_ALL_POSTCODES', 'UPDATE_POSTCODE_GROUP', 'FETCH_SINGLE_POSTCODE_GROUP']),
    async fetchAllPostcodes() {
      try {
        this.UPDATE_LOADER(true)
        this.postcodes = await this.FETCH_ALL_POSTCODES()
        const resp = await this.FETCH_SINGLE_POSTCODE_GROUP(this.$route.params.id)
        this.form.group_name = resp[0].group_name
        this.current_group_name = resp[0].group_name
        this.selectedPostcodes = resp[0].postal_code_group_keys.map(item => item.postal_code)
        this.selectedWeekdays = resp[0].weekdays.filter(item => item.isSelected === true).map(item => item.name)
        this.selectedRepeat = resp[0].weekdays.filter(item => item.isSelected === true).map(item => item.repeatWeek)
        this.selectedStartDate = resp[0].weekdays.filter(item => item.isSelected === true).map(item => item.startDate)
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },
    async onSubmit() {
      this.$refs.updatePostcodeGroupValidator.validate().then(async success => {
        if (success) {
          try {
            this.UPDATE_LOADER(true)
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.selectedWeekdays.length; i++) {
              let index = -1
              const element = this.selectedWeekdays[i]
              if (element === 'Monday') {
                index = 0
              } else if (element === 'Tuesday') {
                index = 1
              } else if (element === 'Wednesday') {
                index = 2
              } else if (element === 'Thursday') {
                index = 3
              } else if (element === 'Friday') {
                index = 4
              } else if (element === 'Saturday') {
                index = 5
              } else if (element === 'Sunday') {
                index = 6
              }
              if (index !== -1) {
                this.form.weekdays[index].isSelected = true
                this.form.weekdays[index].startDate = this.selectedStartDate[i]
                this.form.weekdays[index].repeatWeek = this.selectedRepeat[i]
              }
            }
            this.form.postal_code = this.selectedPostcodes.map(({ id }) => id)
            if (this.form.group_name === this.current_group_name) {
              delete this.form.group_name
            }
            const resp = await this.UPDATE_POSTCODE_GROUP({ id: this.$route.params.id, form: this.form })
            if (resp) {
              this.resetForm()
              this.$nextTick(() => {
                this.$refs.updatePostcodeGroupValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Postcode group Updated!',
                  icon: 'PlusIcon',
                  variant: 'success',
                  text: 'Postcode group has been updated successfully!',
                },
              })
              this.$router.push('/postcode-group')
            }
            this.UPDATE_LOADER(false)
          } catch (error) {
            this.UPDATE_LOADER(false)
          }
        }
      })
    },
    resetForm() {
      this.form.group_name = ''
      this.form.postal_code = []
      this.selectedPostcodes = []
      this.selectedWeekdays = []
      this.selectedRepeat = []
      this.selectedStartDate = []
      this.form.weekdays = [
        {
          name: 'Monday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
        {
          name: 'Tuesday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
        {
          name: 'Wednesday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
        {
          name: 'Thursday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
        {
          name: 'Friday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
        {
          name: 'Saturday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
        {
          name: 'Sunday',
          isSelected: false,
          startDate: null,
          repeatWeek: null,
        },
      ]
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
